export const config = {
  hostname: window.location.hostname,
  origin: window.location.origin,
};

export const iHostname = [
  'dood.eu.org',
  'mdisk.co.in',
  'fdisk.in',
  'mdisk.eu.org',
];
